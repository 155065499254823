import "./styles.css";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./script/checkUnderline";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import {
  FaFacebookSquare,
  FaYoutubeSquare,
  FaInstagramSquare,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Helmet } from "react-helmet";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { useForm } from "@formspree/react";
import "./PreviewPage.scss"; // Import your SCSS file for styling
import Typography from "@mui/material/Typography";
const backgroundVideo = require("./script/test-img/backgroundVideo.mp4");

const PortfolioList = [
  {
    images: "10",
    category: "BRAND",
    title: "redlightcam",
    serve: "/portfolio/redlightcam",
    platform: "",
  },
  {
    images: "3",
    category: "MOBILE GAME",
    title: "Baaridge",
    serve: "/portfolio/baaridge",
    platform: "",
  },
  {
    images: "4",
    category: "MOBILE GAME",
    title: "BL1NK",
    serve: "/portfolio/bl1nk",
    platform: "",
  },

  {
    images: "1",
    category: "MOBILE GAME",
    title: "Keep On Trucking",
    serve: "/portfolio/keep-on-trucking",
    platform: "",
  },
  // {
  //   images: "6",
  //   category: "MOBILE GAME",
  //   title: "Point Five",
  //   serve: "/portfolio/point-five",
  //   platform: "",
  // },

  {
    images: "8",
    category: "MOBILE GAME",
    title: "Stack 8",
    serve: "/portfolio/stack-8",
    platform: "",
  },
];

export default function AppTest() {
  const { pathname, hash, key } = useLocation();
  const [query] = useState("");

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
      console.log(`href: ${window.location.href}`);
      document.getElementById("homeMenuText").style.borderBottom = "solid 2px";
      document.getElementById("homeMenuText").style.borderColor = "red";
    }
    // else scroll to id
    else {
      setTimeout(() => {
        document.getElementById("homeMenuText").style.borderBottom = "none";

        const id = hash.replace("#", "");
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView();

          if (hash === "#about-us") {
            document.getElementById("mediaMenuText").style.borderBottom =
              "none";
            document.getElementById("gamesMenuText").style.borderBottom =
              "none";
            document.getElementById("aboutUsMenuText").style.borderBottom =
              "solid 2px";
            document.getElementById("aboutUsMenuText").style.borderColor =
              "red";
          }

          if (hash === "#portfolio") {
            document.getElementById("gamesMenuText").style.borderBottom =
              "solid 2px";
            document.getElementById("gamesMenuText").style.borderColor = "red";
          }

          if (hash === "#contact-us") {
            document.getElementById("mediaMenuText").style.borderBottom =
              "none";
            document.getElementById("gamesMenuText").style.borderBottom =
              "none";
            document.getElementById("mediaMenuText").style.borderBottom =
              "solid 2px";
            document.getElementById("mediaMenuText").style.borderColor = "red";
          }
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const [state, handleSubmit] = useForm("mbjvqlka");

  if (state.succeeded) {
    return <p>Thanks for your submission!</p>;
  }

  return (
    <div className="container">
      <Helmet>
        <title>THE RISE COLLECTION</title>
        <meta
          name="description"
          content="THE RISE COLLECTION is an internet-based software company. We produce
          and publish arcade style games on multiple platforms. Our games are
          increasingly difficult and designed to be replayed."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="flex-box-header header">
        <div className="headerText">
          <a href="/">
            <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="#portfolio" onClick={underlineMenuPortfolio}>
            <p id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>
      <div
        id="/"
        style={{
          backgroundColor: "black",
        }}
        className="section-home"
      >
        <video
          autoPlay={true}
          loop={true}
          controls={false}
          playsInline
          muted
          id="video"
        >
          <source src={backgroundVideo} type="video/mp4" />
        </video>
      </div>
      <div
        id="about-us"
        className="section"
        style={{
          backgroundColor: "#0b0b0b",
          overflowX: "hidden",
        }}
      >
        <h2
          id="aboutUsHeader"
          style={{
            color: "white",
            marginTop: "35vh",
            textAlign: "center",
            fontSize: "30px",
          }}
        >
          ABOUT US
        </h2>

        <p
          id="about1"
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            paddingRight: "3px",
            paddingLeft: "20px",
            color: "white",
            fontSize: "20px",
          }}
        >
          THE RISE COLLECTION is an internet-based software company. We produce
          and publish arcade style games on multiple platforms. Our games are
          increasingly difficult and designed to be replayed.
          <br></br>
          <br></br>
          View the team{" "}
          <a
            href="https://www.linkedin.com/company/therisecollection"
            style={{ color: "red" }}
          >
            here.
          </a>
        </p>
        <p
          id="about3"
          style={{
            color: "white",
            display: "block",
            marginBlockStart: "1em",
            marginBlockEnd: "1em",
            marginInlineStart: "18px",
            lineHeight: "25px",
            marginInlineEnd: "18px",
            fontSize: "20px",
            textAlign: "left",
            marginBottom: "15px",
          }}
        ></p>
      </div>
      <div
        id="portfolio"
        className="section"
        style={{
          backgroundColor: "#0b0b0b",
          overflowX: "hidden",
        }}
      >
        <div
          className="gridContainer"
          style={{ padding: "25vh 15px 175px 15px" }}
        >
          {PortfolioList.filter((value) => {
            if (query === "") {
              return value;
            } else if (
              value.title.toLowerCase().includes(query.toLowerCase())
            ) {
              return value;
            }
            return value;
          }).map((value, i) => (
            <div>
              <div key={i}>
                <a href={value.serve} style={{ textDecoration: "none" }}>
                  <Card
                    style={{ backgroundColor: "black" }}
                    sx={{ minWidth: 345 }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        image={`/assets/images/portfolio/portfolio-${value.images}.jpg`}
                        alt="Portfolio Images"
                        backgroundColor="black"
                      />
                      <CardContent style={{ backgroundColor: "black" }}>
                        <Typography gutterBottom variant="h5" component="div">
                          <a
                            style={{ color: "white", textDecoration: "none" }}
                            href={value.serve}
                          >
                            {value.title}
                          </a>
                        </Typography>
                        <Typography variant="subtitle1" color="red">
                          {value.category} {value.platform}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        id="contact-us"
        className="section"
        style={{
          backgroundColor: "#0b0b0b",
          overflowX: "hidden",
        }}
      >
        <h2
          id="contactUsHeader"
          style={{
            color: "white",
            marginTop: "25vh",
            textAlign: "center",
            fontSize: "30px",
          }}
        >
          CONTACT US
        </h2>
        <p
          style={{
            color: "white",
            maxWidth: "800px",
            margin: "0 auto",
            paddingRight: "3px",
            paddingLeft: "25px",
            fontSize: "20px",
          }}
        >
          Have a question? Want to learn more about what we develop? Reach out
          using the form below!
        </p>

        <div className="form-container">
          <form
            className="centered-form"
            action="https://formspree.io/f/mbjvqlka"
            method="post"
          >
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label style={{ color: "white" }} htmlFor="email"></label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="your@email.com"
              />
            </div>
            <div className="form-group">
              <label style={{ color: "white" }} htmlFor="message"></label>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                style={{ resize: "none", width: "100%", height: "100px" }}
              ></textarea>
            </div>
            <button type="submit" onSubmit={handleSubmit}>
              Submit
            </button>
          </form>
        </div>
      </div>

      <div className="section" id="footerBackground">
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-social">
              <a
                href="https://www.instagram.com/rwtrcsc/"
                aria-label="instagram-link"
              >
                <FaInstagramSquare
                  style={{
                    color: "white",
                    height: "35px",
                    width: "25px",
                    marginRight: "15px",
                  }}
                />
              </a>

              <a
                href="https://www.facebook.com/rwtrcsc/"
                aria-label="facebook-link"
              >
                <FaFacebookSquare
                  style={{
                    color: "white",
                    height: "35px",
                    width: "25px",
                    marginRight: "15px",
                  }}
                />
              </a>
              <a href="https://twitter.com/rwtrcsc">
                <FaSquareXTwitter
                  FaSquareXTwitter
                  style={{
                    color: "white",
                    height: "35px",
                    width: "25px",
                    marginRight: "15px",
                  }}
                ></FaSquareXTwitter>
              </a>

              <a
                href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q"
                aria-label="youtube-link"
              >
                <FaYoutubeSquare
                  style={{
                    color: "white",
                    height: "35px",
                    width: "25px",
                    marginRight: "15px",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/therisecollection"
                aria-label="linkedin-link"
              >
                <FaLinkedin
                  style={{ color: "white", height: "35px", width: "25px" }}
                />
              </a>
              <br></br>
              <br></br>
              <a
                href="/privacy-policy"
                style={{
                  color: "white",
                  borderBottom: "solid 2px",
                  borderColor: "red",
                  textDecoration: "none",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                PRIVACY POLICY
              </a>
              <br></br>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
