import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./script/checkUnderline";
import './PreviewPage.scss'; // Import your SCSS file for styling
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";

const iconImage = require("../src/script/test-img/portfolio-4.JPEG");
// const androidImage = require("../src/script/test-img/android.png");
const appleImage = require("../src/script/test-img/apple.png");
const screenShot1 = require("../src/script/test-img/bl1nk-1.png");
const screenShot2 = require("../src/script/test-img/bl1nk-2.png");
const screenShot3 = require("../src/script/test-img/bl1nk-3.png");
const TITLE = 'BL1NK - THE RISE COLLECTION';

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const BL1NKDetailPage = () => {
  useTitle("BL1NK - THE RISE COLLECTION")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 style={{ fontSize: "30px" }}>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>
      <div className="app-container">
        <div className="game-info">
          <img src={iconImage} alt="Game Icon" className="game-icon" />
          <div className="game-details">
            <h2 style={{ fontSize: "18px" }}>BL1NK</h2>
            <p style={{ fontSize: '16px', color: 'red', fontWeight: 'bold' }}>THE RISE COLLECTION</p>
            <div className='tag-container'>
              <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', width: '125px', paddingTop: '0.98px' }}>MOBILE GAME</div>
              <div className='left-tag' style={{ background: 'blue', width: '35px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>IOS</div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ marginBottom: '20px' }}>
          <h2>About the Mobile Game</h2>
          <p style={{ fontSize: '18px' }}>
            Avoid obstacles by tilting your device! BL1NK relies on your device's Accelerometer to move the falling cube.


            How long can you fall before you BL1NK? Use the sensors in your device to control the player as you fall down the map..
          </p>
          <hr />

        </div>
        <h2>Media</h2>

        <div className="screenshot-container-sideways">

          <img src={screenShot1} alt='BL1NK_SCREENSHOT_1' />
          <img src={screenShot3} alt='BL1NK_SCREENSHOT_3' />

          <img src={screenShot2} alt='BL1NK_SCREENSHOT_2' />

        </div>
        <hr />
        <div style={{ marginBottom: '20px' }}>
          <h2>Additional Information</h2>
          <ul style={{ fontSize: '18px' }}>
            <li>Release Date: July 15, 2017</li>
            <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
              style={{ color: "red", }}>THE RISE COLLECTION</a></li>
          </ul>
        </div>
        <hr />
        <h2>Download</h2>
        <div className="game-description">


          <a href='https://apps.apple.com/mm/app/bl1nk/id1025073240'><img src={appleImage} style={{ width: '175px', marginLeft: '-10px' }} alt='BL1NK_DOWNLOAD_IOS' /></a>
          {/* <a href='https://play.google.com/store/apps/details?id=com.therisecollection.blink&hl=en_US&gl=US'><img src={androidImage} style={{ width: '175px', marginLeft: '-10px' }} alt='BL1NK_DOWNLOAD_ANDROID' /></a> */}

        </div>


      </div>
      <br></br>
      <footer className="footer" id='detail-footer'>

        <div className="footer-content">
          <div className="footer-social">
            <a href="https://www.instagram.com/rwtrcsc/" aria-label="instagram-link">
              <FaInstagramSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>

            <a href="https://www.facebook.com/rwtrcsc/" aria-label="facebook-link">
              <FaFacebookSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://twitter.com/rwtrcsc">
              <FaSquareXTwitter FaSquareXTwitter style={{
                color: "white",
                height: "35px",
                width: "25px",
                marginRight: "15px",
              }}></FaSquareXTwitter >

            </a>

            <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" aria-label="youtube-link">
              <FaYoutubeSquare
                style={{
                  color: "white",
                  height: "35px",
                  width: "25px",
                  marginRight: "15px",
                }}
              />
            </a>
            <a href="https://www.linkedin.com/company/therisecollection" aria-label="linkedin-link">
              <FaLinkedin
                style={{ color: "white", height: "35px", width: "25px" }}
              />
            </a>
            <br></br>
            <br></br>
            <a href="/privacy-policy" style={{ color: 'white', borderBottom: 'solid 2px', borderColor: 'red', textDecoration: 'none', fontFamily: 'Roboto, sans-serif' }}>PRIVACY POLICY</a>
            <br></br>
          </div>
        </div>
      </footer>
    </div>
  )


}



export default BL1NKDetailPage
